/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationSignature } from './applicationSignature';
import { InternalBusinessProfile } from './internalBusinessProfile';
import { BankAccountUpdateParams } from './bankAccountUpdateParams';
import { ProcessingInformation } from './processingInformation';


export interface PartnerApplicationResponse { 
    account_id: string;
    account_name?: string;
    bank_account?: BankAccountUpdateParams;
    business_profile?: InternalBusinessProfile;
    created_at: string;
    id: string;
    processing_information?: ProcessingInformation;
    sent_to_partner_at: string;
    signature: ApplicationSignature;
    started_at: string;
    status: PartnerApplicationResponse.StatusEnum;
    submitted_at: string;
    updated_at: string;
}
export namespace PartnerApplicationResponse {
    export type StatusEnum = 'created' | 'started' | 'submitted';
    export const StatusEnum = {
        CREATED: 'created' as StatusEnum,
        STARTED: 'started' as StatusEnum,
        SUBMITTED: 'submitted' as StatusEnum
    };
}


