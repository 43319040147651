/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DisputeFile { 
    /**
     * The file.
     */
    file: any;
    /**
     * The type of evidence provided in the file.
     */
    type: DisputeFile.TypeEnum;
}
export namespace DisputeFile {
    export type TypeEnum = 'customer_communications' | 'refund_policy' | 'cancellation_policy' | 'customer_signature' | 'receipt' | 'service_documentation' | 'duplicate_charge_documentation' | 'shipping_documentation' | 'uncategorized';
    export const TypeEnum = {
        CUSTOMER_COMMUNICATIONS: 'customer_communications' as TypeEnum,
        REFUND_POLICY: 'refund_policy' as TypeEnum,
        CANCELLATION_POLICY: 'cancellation_policy' as TypeEnum,
        CUSTOMER_SIGNATURE: 'customer_signature' as TypeEnum,
        RECEIPT: 'receipt' as TypeEnum,
        SERVICE_DOCUMENTATION: 'service_documentation' as TypeEnum,
        DUPLICATE_CHARGE_DOCUMENTATION: 'duplicate_charge_documentation' as TypeEnum,
        SHIPPING_DOCUMENTATION: 'shipping_documentation' as TypeEnum,
        UNCATEGORIZED: 'uncategorized' as TypeEnum
    };
}


