/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodCreateParamsCard } from './paymentMethodCreateParamsCard';
import { PaymentMethodCreateParamsAchDebit } from './paymentMethodCreateParamsAchDebit';
import { PaymentMethodCreateParamsBillingDetails } from './paymentMethodCreateParamsBillingDetails';
import { PaymentMethodCreateParamsEftDebit } from './paymentMethodCreateParamsEftDebit';


export interface PaymentMethodCreateParams { 
    /**
     * If this is an `ach_debit` PaymentMethod, this hash contains details about the ACH Direct Debit bank account.
     */
    ach_debit?: PaymentMethodCreateParamsAchDebit;
    /**
     * Billing information associated with the PaymentMethod that may be used or required by most types of payment methods.
     */
    billing_details?: PaymentMethodCreateParamsBillingDetails;
    /**
     * If this is a `card` PaymentMethod, this hash contains the user\'s card details. When providing a card number, you must meet the requirements for PCI compliance. We strongly recommend using Tilled.js instead of interacting with this API directly.
     */
    card?: PaymentMethodCreateParamsCard;
    /**
     * If this is an `eft_debit` PaymentMethod, this hash contains details about the EFT Direct Debit bank account.
     */
    eft_debit?: PaymentMethodCreateParamsEftDebit;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. Individual keys can be unset by posting an empty value (i.e. `\'\'` or `null`) to them. All keys can be unset by posting an empty value (i.e. `{}` or `null`) to `metadata`.
     */
    metadata?: { [key: string]: string; };
    /**
     * A customer provided name for the PaymentMethod.
     */
    nick_name?: string;
    /**
     * A token generated by Tilled.js representing the details of a payment method (e.g. card or bank details)
     */
    payment_token?: string;
    /**
     * Required if this is a `card_present` PaymentMethod, this is the id of terminal reader you want to use to collect card information. Starts with a prefix `term_`
     */
    terminal_reader_id?: string;
    /**
     * The type of the PaymentMethod. An additional hash is included on the PaymentMethod with a name matching this value. It contains additional information specific to the PaymentMethod type. If the selected type is `card_present`, then `terminal_reader_id` is required.
     */
    type: PaymentMethodCreateParams.TypeEnum;
}
export namespace PaymentMethodCreateParams {
    export type TypeEnum = 'card' | 'ach_debit' | 'eft_debit' | 'card_present';
    export const TypeEnum = {
        CARD: 'card' as TypeEnum,
        ACH_DEBIT: 'ach_debit' as TypeEnum,
        EFT_DEBIT: 'eft_debit' as TypeEnum,
        CARD_PRESENT: 'card_present' as TypeEnum
    };
}


