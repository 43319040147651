import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule, KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { PaysafeApplicationSteps, TsysApplicationSteps } from 'app/core/data/onboarding-types';
import { SelectorTypes } from 'app/core/data/selector-types';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import {
  LegalNameVerificationStatus,
  MiddeskBusinessVerification,
  MiddeskBusinessWithVerification,
  TinVerificationStatus,
} from 'app/core/services/middesk.app.service';
import { AutocompleteComponent } from 'app/shared/autocomplete/autocomplete.component';
import { TilledButtonComponent } from 'app/shared/buttons/tilled-button.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { TilledHeadingH4Component, TilledHeadingH5Component, TilledParagraphP3Component } from 'app/shared/tilled-text';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { middeskEntityToTilledStructure } from 'app/shared/utils/onboarding-utils';
import { isOlderThan, isValidYear } from 'app/shared/validators/dob.validator';
import { isPoBox } from 'app/shared/validators/po-box.validator';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscription, delay, take, takeUntil } from 'rxjs';
import {
  InternalPartnerOnboardingDefaultSettings,
  MiddeskSuggestions,
  OnboardingAddress,
  OnboardingApplication,
  OnboardingLegalEntity,
  PricingTemplate,
  SuggestionOptions,
} from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { MiddeskBusiness } from './../../../../../projects/tilled-api-client/src/model/middeskBusiness';

@Component({
  selector: 'business-details-merchant-step',
  templateUrl: './business-details-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledLabelL1Component,
    TilledSelectComponent,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    AsyncPipe,
    TilledHeadingH5Component,
    TilledHeadingH4Component,
    TilledParagraphP3Component,
    AutocompleteComponent,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDividerModule,
    CommonModule,
    TilledButtonComponent,
    MatProgressBarModule,
  ],
})
export class BusinessDetailsStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Input() partnerDefaultSettings$: Observable<InternalPartnerOnboardingDefaultSettings>;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  public title = 'Business Details';
  public description = 'Tell us about your business.';
  public busTypeStep: boolean = false;
  public businessDetailsForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public entityTypes = SelectorTypes.businessEntityTypes;
  public entityTypesOptions: { label: string; value: string }[] = this.entityTypes.map((type) => ({
    label: type.desc,
    value: type.name,
  }));
  public windowWidth: any;
  public isLoading: boolean = false;
  public progressValue$ = new BehaviorSubject<number>(0);
  public tilledInputWidth: any;
  public isFocused = false;
  private subscriptions: Subscription[] = [];
  public hasTsysProvider: boolean;
  public middeskVerification: MiddeskBusinessVerification;
  public middeskEnabled: boolean;
  public middeskEnabled$: Observable<boolean>;
  public middeskBusiness: MiddeskBusiness;
  public middeskBusiness$: Observable<MiddeskBusiness>;
  public skipVerification: boolean = false;
  public providedFeedback: Array<'tinNameReview' | 'updateName' | 'updateStructure'> = [];
  public verificationAlerts: VerificationAlertConfig[] = [];
  public isCanadian: boolean = false;

  public stateCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.stateAndProvinceMap).map(
    ([value, label]) => ({ label, value }),
  );
  private stateAndProvinceMap = SelectorTypes.stateAndProvinceMap;

  private onboardingDefaults: InternalPartnerOnboardingDefaultSettings;
  public paysafeSteps: typeof PaysafeApplicationSteps = PaysafeApplicationSteps;
  public tsysSteps: typeof TsysApplicationSteps = TsysApplicationSteps;
  public isWhiteLabel$: Observable<boolean>;

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _brandingService: BrandingService,
    private _alertService: AlertService,
  ) {
    super();
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
    this.partnerDefaultSettings$ = this._merchantAppService.partnerOnboardingDefaults$;
    this.middeskBusiness$ = this._merchantAppService.middeskBusiness$;
    this.middeskEnabled$ = this._merchantAppService.middeskEnabled$;
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
    this.tilledInputWidth =
      document?.querySelector('tilled-input mat-form-field')?.clientWidth + 'px' ||
      document?.querySelector('tilled-select mat-form-field')?.clientWidth + 'px';
  }

  ngOnInit(): void {
    this.determineTitle(this.stepNumber);

    if (this.busTypeStep) {
      this.businessDetailsForm = this._formBuilder.group({
        type: new FormControl<string | null>(this.merchantApp?.legal_entity?.structure || null, [Validators.required]),
      });
    }
    if (!this.busTypeStep) {
      this.businessDetailsForm = this._formBuilder.group({
        legalName: new FormControl<string | null>(this.merchantApp?.legal_entity?.legal_name || null),
        dba: new FormControl<string | null>(
          this.merchantApp?.legal_entity?.dba_name || this.merchantApp?.legal_entity?.legal_name || null,
        ),
        businessIdentifier: new FormControl<string | null>(this.merchantApp?.legal_entity?.tax_id_number || null, [
          Validators.minLength(9),
        ]),
        dateOfInc: new FormControl<string | null>(
          this.merchantApp?.legal_entity.date_of_incorporation
            ? moment(this.merchantApp?.legal_entity.date_of_incorporation).format('MM/DD/YYYY')
            : null,
          [isValidYear(1600), isOlderThan(0)],
        ),
        street: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.street || null, [isPoBox()]),
        street2: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.street2 || null),
        city: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.city || null),
        state: new FormControl<string | null>(
          SelectorTypes.stateAndProvinceMap.get(this.merchantApp?.legal_entity?.address?.state) || null,
        ),
        zip: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.postal_code || null),
        country: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.country || null),
      });
    }

    this.partnerDefaultSettings$.subscribe((result) => {
      if (result) {
        this.onboardingDefaults = result;
      }
    });
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });
    if (this.merchantApp?.legal_entity && !this.merchantApp.legal_entity.address) {
      this.merchantApp.legal_entity.address = {} as OnboardingAddress;
    }

    this.middeskBusiness$.pipe(take(2)).subscribe((business) => {
      if (business) {
        this.middeskBusiness = business;
      }
    });
    this.middeskEnabled$.subscribe((enabled) => {
      this.middeskEnabled = enabled;
    });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.businessDetailsForm.disable();
          } else {
            this.businessDetailsForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.windowWidth = window.innerWidth;
    document.dispatchEvent(new MouseEvent('mousemove'));
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public setBusinessAddress(address: OnboardingAddress): void {
    this.businessDetailsForm.patchValue({
      street: address.street,
      city: address.city,
      state: address.state ? [...this.stateAndProvinceMap].find(([key, val]) => key === address.state)[0] : null,
      zip: address.postal_code,
    });
    this.businessDetailsForm.markAsTouched();
  }

  onContinueClicked(accountId?: string): void {
    if (this.forConsole && !accountId) {
      accountId = this._route.snapshot.params.id;
    }
    this.businessDetailsForm.markAllAsTouched();
    if (this.businessDetailsForm.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }
    // Needed to increment by 0.1 to avoid issues with the step number being a float.
    let subIncrement = (this.stepNumber + 0.1).toFixed(1);
    // used to determine if application needs to be saved (avoiding unnecessary API calls)
    const unsaved = this.isAppUnsaved();

    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.businessDetailsForm.controls) {
      const control = this.businessDetailsForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    if (this.busTypeStep) {
      this.merchantApp.legal_entity.structure = this.businessDetailsForm.value.type;
      this._merchantAppService.updateMerchantApplication(this.merchantApp, parseFloat(subIncrement), accountId);
      return;
    }
    if (!this.busTypeStep) {
      this.mapFormToApp();

      if (
        !this.middeskEnabled ||
        this.skipVerification ||
        (!this._merchantAppService.isBusinessDetailsSubStepComplete(this.merchantApp).complete && !this.middeskBusiness)
      ) {
        this._merchantAppService.updateMerchantApplication(this.merchantApp, parseFloat(subIncrement), accountId);
        return;
      }
      if (this.middeskBusiness) {
        this.handleVerificationStatus(accountId);
        return;
      }

      if (!this.middeskBusiness) {
        this.isLoading = true;
        this.progressValue$.pipe(delay(5), takeUntil(this._unsubscribeAll)).subscribe(() => {
          this.progressValue$.next(100);
        });
        this._merchantAppService
          .createMiddeskBusiness(AuthService.getCurrentAccountId(), this.merchantApp)
          .then((result) => {
            if (result) {
              this.middeskBusiness$.subscribe((business) => {
                if (business) {
                  this.middeskBusiness = business;
                }
              });
            }
            if (this.middeskBusiness) {
              this.handleVerificationStatus(accountId);
              this._changeDetectorRef.markForCheck();
              return;
            } else {
              this.isLoading = false;
              if (unsaved) {
                this._merchantAppService.updateMerchantApplication(
                  this.merchantApp,
                  parseFloat(subIncrement),
                  accountId,
                );
              } else {
                this._merchantAppService.updateCurrentStep(parseFloat(subIncrement));
              }
              return;
            }
          });
      }
    }
  }

  private isAppUnsaved(): boolean {
    let appResponse: OnboardingApplication;
    this._merchantAppService.merchantApplicationResponse$.pipe(take(1)).subscribe((app) => {
      appResponse = app;
    });
    if (this.busTypeStep) {
      return !_compareTwoStrings(this.merchantApp.legal_entity?.structure, this.businessDetailsForm.value.type);
    }
    if (!this.busTypeStep) {
      const state = this.businessDetailsForm.value.state
        ? [...this.stateAndProvinceMap].find(([key, val]) => key === this.businessDetailsForm.value.state)[0]
        : null;
      return !(
        _compareTwoStrings(this.merchantApp.legal_entity?.legal_name, this.businessDetailsForm.value.legalName) &&
        _compareTwoStrings(appResponse?.legal_entity?.legal_name, this.merchantApp.legal_entity?.legal_name) &&
        _compareTwoStrings(this.merchantApp.legal_entity?.dba_name, this.businessDetailsForm.value.dba) &&
        _compareTwoStrings(
          this.merchantApp.legal_entity?.tax_id_number,
          this.businessDetailsForm.value.businessIdentifier,
        ) &&
        _compareTwoStrings(
          this.businessDetailsForm.value.dateOfInc,
          this.merchantApp.legal_entity?.date_of_incorporation
            ? moment(this.merchantApp.legal_entity?.date_of_incorporation).format('MM/DD/YYYY').toString()
            : null,
        ) &&
        _compareTwoStrings(this.merchantApp.legal_entity?.address?.street, this.businessDetailsForm.value.street) &&
        _compareTwoStrings(this.merchantApp.legal_entity?.address?.street2, this.businessDetailsForm.value.street2) &&
        _compareTwoStrings(this.merchantApp.legal_entity?.address?.city, this.businessDetailsForm.value.city) &&
        _compareTwoStrings(this.merchantApp.legal_entity?.address?.state, state) &&
        _compareTwoStrings(this.merchantApp.legal_entity?.address?.postal_code, this.businessDetailsForm.value.zip) &&
        _compareTwoStrings(this.merchantApp.legal_entity?.address?.country, SelectorTypes.getCountryFromState(state)) &&
        _compareTwoStrings(appResponse?.legal_entity?.structure, this.merchantApp.legal_entity?.structure)
      );
    }
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.legal_entity;
    if (this.busTypeStep) {
      this.businessDetailsForm.controls['type'].setValue(ble?.structure);
      if (this.onboardingDefaults) {
        if (this.onboardingDefaults.business_types) {
          const filteredOptions = this.entityTypesOptions.filter((entity) =>
            this.onboardingDefaults.business_types.includes(entity.value as any),
          );
          this.entityTypesOptions = filteredOptions;
        }
      }
    }
    if (!this.busTypeStep) {
      const state = ble?.address?.state
        ? [...this.stateAndProvinceMap].find(([key, val]) => key === ble?.address?.state)[0]
        : null;
      this.businessDetailsForm.controls['legalName']?.setValue(ble?.legal_name);
      this.businessDetailsForm.controls['dba']?.setValue(ble?.dba_name || ble?.legal_name);
      this.businessDetailsForm.controls['businessIdentifier']?.setValue(ble?.tax_id_number);
      this.businessDetailsForm.controls['dateOfInc']?.setValue(
        ble?.date_of_incorporation ? moment(ble.date_of_incorporation).format('MM/DD/YYYY') : null,
      );
      this.businessDetailsForm.controls['street']?.setValue(ble?.address?.street);
      this.businessDetailsForm.controls['street2']?.setValue(ble?.address?.street2);
      this.businessDetailsForm.controls['city']?.setValue(ble?.address?.city);
      this.businessDetailsForm.controls['state']?.setValue(state);
      this.businessDetailsForm.controls['zip']?.setValue(ble?.address?.postal_code);
      this.businessDetailsForm.controls['country']?.setValue(SelectorTypes.getCountryFromState(ble?.address?.state));

      this.isCanadian = this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.CAD;

      // Update defaults after any app reset.
      if (this.onboardingDefaults) {
      }
      this._changeDetectorRef.markForCheck();
    }
  }

  public mapFormToApp(): void {
    this.merchantApp.legal_entity.legal_name = this.businessDetailsForm.value.legalName;
    this.merchantApp.legal_entity.dba_name = this.businessDetailsForm.value.dba;
    this.merchantApp.legal_entity.tax_id_number = this.businessDetailsForm.value.businessIdentifier;
    this.merchantApp.legal_entity.date_of_incorporation = moment(
      this.businessDetailsForm.value.dateOfInc,
      'MM-DD-YYYY',
    ).toISOString();
    this.merchantApp.legal_entity.address.street = this.businessDetailsForm.value.street;
    this.merchantApp.legal_entity.address.street2 = this.businessDetailsForm.value.street2;
    this.merchantApp.legal_entity.address.city = this.businessDetailsForm.value.city;
    this.merchantApp.legal_entity.address.state = this.businessDetailsForm.value.state
      ? [...this.stateAndProvinceMap].find(([key, val]) => key === this.businessDetailsForm.value.state)[0]
      : null;
    this.merchantApp.legal_entity.address.postal_code = this.businessDetailsForm.value.zip;
    this.merchantApp.legal_entity.address.country = SelectorTypes.getCountryFromState(
      this.merchantApp.legal_entity.address.state,
    );
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  public readable(input: string): string {
    let pieces = input.toLowerCase().split('_');
    for (let i = 0; i < pieces.length; i++) {
      if (!(pieces[i] === 'and' || pieces[i] === 'of' || pieces[i] === 'to' || pieces[i] === 'to')) {
        pieces[i] = pieces[i].charAt(0).toUpperCase() + pieces[i].slice(1);
      }
    }
    return pieces.join(' ');
  }

  public determineTitle(step: number): void {
    /* eslint-disable quotes */
    switch (step) {
      case PaysafeApplicationSteps.BUSINESS_TYPE_SUB_STEP:
      case TsysApplicationSteps.BUSINESS_TYPE_SUB_STEP:
        this.title = "Let's start with the basics";
        this.description = 'Choose your business type to get started.';
        this.busTypeStep = true;
        break;
      case PaysafeApplicationSteps.BUSINESS_DETAILS_SUB_STEP:
      case TsysApplicationSteps.BUSINESS_DETAILS_SUB_STEP:
        this.title = 'Business details';
        this.description = 'Tell us about your business.';
        this.busTypeStep = false;
        break;
      default:
        this.title = 'Business details';
        this.description = 'Tell us about your business.';
        this.busTypeStep = false;
        break;
    }
  }

  public handleVerificationStatus(accountId?: string, review?: boolean): void {
    this.isLoading = false;
    const business = this.middeskBusiness as MiddeskBusinessWithVerification;
    const suggestions = business?.suggestions as MiddeskSuggestions;

    if (suggestions) {
      if (suggestions?.name) {
        this.providedFeedback.push('updateName');
      }
      if (suggestions?.entity_type) {
        this.providedFeedback.push('updateStructure');
      }
      if (suggestions?.tin) {
        this.providedFeedback.push('tinNameReview');
      }
    }

    const nameMismatchStatus = [LegalNameVerificationStatus.ALTERNATE_NAME, LegalNameVerificationStatus.SIMILAR_MATCH];

    const unknownEntityStructures = [
      OnboardingLegalEntity.StructureEnum.CHARITY,
      OnboardingLegalEntity.StructureEnum.COMMUNITY_INTEREST_COMPANY,
      OnboardingLegalEntity.StructureEnum.LIMITED,
      OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_PARTNERSHIP,
      OnboardingLegalEntity.StructureEnum.PUBLIC_LIMITED_COMPANY,
      OnboardingLegalEntity.StructureEnum.GOVERNMENT,
    ];

    const isUnknownEntityMatch =
      unknownEntityStructures.includes(this.merchantApp?.legal_entity?.structure) &&
      business?.entity_type === MiddeskBusiness.EntityTypeEnum.UNKNOWN;

    if (
      [TinVerificationStatus.MISMATCH, TinVerificationStatus.NOT_FOUND].includes(business?.verification?.tinStatus) &&
      !this.providedFeedback.includes('tinNameReview')
    ) {
      const tinMismatchAlert: VerificationAlertConfig = {
        title: 'Review your legal business name and Tax ID',
        message:
          "The legal business name and Tax ID you entered cannot be verified with your state's Secretary of State.\n\n" +
          "For a smoother application process, the legal business name and Tax ID must exactly match your business' registration documents.",
        order: 1,
        type: 'tinNameReview',
        reviewText: 'Review',
        ignoreText: 'Ignore',
      };
      this.verificationAlerts.push(tinMismatchAlert);
    }

    if (
      nameMismatchStatus.includes(business?.verification?.legalNameStatus) &&
      this.merchantApp?.legal_entity?.legal_name !== business?.legal_name_of_business &&
      !this.providedFeedback.includes('updateName')
    ) {
      const nameMismatchAlert: VerificationAlertConfig = {
        title: 'Confirm your legal business name',
        message:
          'Your legal business name must match the name on your state registration documents. Based on our check with the Secretary of State, your legal business name may be:' +
          `\n<span class="font-semibold font-openSans">${business?.legal_name_of_business}</span>\n\n` +
          'If this is correct, click "Update Legal Name" to update your legal business name. Otherwise, you can ignore this suggestion.',
        order: 0,
        type: 'updateName',
        updateText: 'Update Legal Name',
        ignoreText: 'Ignore',
      };

      this.verificationAlerts.push(nameMismatchAlert);
    }

    if (
      business?.entity_type &&
      !(
        isUnknownEntityMatch ||
        (middeskEntityToTilledStructure(business?.entity_type) === this.merchantApp?.legal_entity?.structure &&
          business?.entity_type !== MiddeskBusiness.EntityTypeEnum.UNKNOWN)
      ) &&
      !this.providedFeedback.includes('updateStructure')
    ) {
      const entityMatchAlert: VerificationAlertConfig = {
        title: 'Confirm your business type',
        message:
          `Based on information from your state's Secretary of State, your business might be registered as a ` +
          `<span class="font-semibold font-openSans">${business?.entity_type
            ?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}</span>.\n\n` +
          'If this is correct, click "Update Business Type" to update your business type. Otherwise, you can ignore this suggestion.',
        order: 2,
        type: 'updateStructure',
        updateText: 'Update Business Type',
        ignoreText: 'Ignore',
      };
      this.verificationAlerts.push(entityMatchAlert);
    }
    this.verificationAlerts = this.verificationAlerts
      .filter((value, index, self) => index === self.findIndex((t) => t.type === value.type))
      .sort((a, b) => a.order - b.order);

    if (review) {
      return;
    }

    if (!this.verificationAlerts.length) {
      this.skipVerification = true;
      this.onContinueClicked(accountId);
    } else {
      this.scrollTo(document.querySelector('.top-of-form'));
    }
    return;
  }

  public handleVerificationAction(alert: VerificationAlertConfig, update?: boolean): void {
    let review = false;
    let suggestions: MiddeskSuggestions = this.middeskBusiness?.suggestions;

    if (update) {
      let message: TilledAlert = {
        message: '',
        type: 'success',
        timer: 5000,
      };
      switch (alert.type) {
        case 'tinNameReview':
          review = true;
          this.scrollTo(document.querySelector("tilled-input[name='legalName']"));
          document.querySelector("tilled-input[name='legalName'] mat-form-field")?.classList.add('mat-focused');
          suggestions.tin = SuggestionOptions.ACCEPTED;
          break;
        case 'updateName':
          // Since onContinueClicked updates the merchantApp, we update the form control.
          this.businessDetailsForm.patchValue({ legalName: this.middeskBusiness?.legal_name_of_business });
          // Ensure that the legal name is updated when saving the application (no call to onContinueClicked)
          this.merchantApp.legal_entity.legal_name = this.businessDetailsForm.value.legalName;
          message.title = 'Legal business name updated';
          suggestions.name = SuggestionOptions.ACCEPTED;
          break;
        case 'updateStructure':
          this.merchantApp.legal_entity.structure = middeskEntityToTilledStructure(this.middeskBusiness?.entity_type);
          message.title = 'Business type updated';
          suggestions.entity_type = SuggestionOptions.ACCEPTED;
          break;
        default:
          break;
      }
      if (this.verificationAlerts?.length > 1 && !review) {
        this.mapFormToApp();
        this._merchantAppService.updateMerchantApplication(
          this.merchantApp,
          this.stepNumber,
          this.middeskBusiness?.account_id,
        );
        this._alertService.showAlert(message);
      }
    } else {
      switch (alert.type) {
        case 'tinNameReview':
          suggestions.tin = SuggestionOptions.IGNORED;
          break;
        case 'updateName':
          suggestions.name = SuggestionOptions.IGNORED;
          break;
        case 'updateStructure':
          suggestions.entity_type = SuggestionOptions.IGNORED;
          break;
        default:
          break;
      }
    }

    this._merchantAppService.updateMiddeskBusiness(this.middeskBusiness?.account_id, { suggestions });
    this.verificationAlerts = this.verificationAlerts.filter((alert) => alert.type !== alert.type);
    this.providedFeedback.push(alert.type);
    this.handleVerificationStatus(this.middeskBusiness?.account_id, review);
  }
}

export type VerificationAlertConfig = {
  title: string;
  message: string;
  order: number;
  type: 'tinNameReview' | 'updateName' | 'updateStructure';
  // ↓ if blank, button will not be shown ↓
  updateText?: string;
  reviewText?: string;
  ignoreText?: string;
};
