/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CardChecks } from './cardChecks';


export interface CardDetails { 
    /**
     * Whether or not the card is an apple pay card
     */
    apple_pay?: boolean;
    /**
     * Type of card.
     */
    brand?: CardDetails.BrandEnum;
    /**
     * Checks on Card address and CVC if provided.
     */
    checks?: CardChecks;
    /**
     * Two-digit number representing the card\'s expiration month.
     */
    exp_month?: number;
    /**
     * Two- or four-digit number representing the card\'s expiration year.
     */
    exp_year?: number;
    /**
     * Card funding type.
     */
    funding?: CardDetails.FundingEnum;
    /**
     * The card holder\'s name.
     */
    holder_name?: string;
    /**
     * Last 4 digits of the card.
     */
    last4?: string;
}
export namespace CardDetails {
    export type BrandEnum = 'amex' | 'diners' | 'discover' | 'jcb' | 'maestro' | 'mastercard' | 'solo' | 'visa' | 'visa_debit' | 'visa_electron' | 'unknown';
    export const BrandEnum = {
        AMEX: 'amex' as BrandEnum,
        DINERS: 'diners' as BrandEnum,
        DISCOVER: 'discover' as BrandEnum,
        JCB: 'jcb' as BrandEnum,
        MAESTRO: 'maestro' as BrandEnum,
        MASTERCARD: 'mastercard' as BrandEnum,
        SOLO: 'solo' as BrandEnum,
        VISA: 'visa' as BrandEnum,
        VISA_DEBIT: 'visa_debit' as BrandEnum,
        VISA_ELECTRON: 'visa_electron' as BrandEnum,
        UNKNOWN: 'unknown' as BrandEnum
    };
    export type FundingEnum = 'credit' | 'debit' | 'prepaid' | 'unknown';
    export const FundingEnum = {
        CREDIT: 'credit' as FundingEnum,
        DEBIT: 'debit' as FundingEnum,
        PREPAID: 'prepaid' as FundingEnum,
        UNKNOWN: 'unknown' as FundingEnum
    };
}


