/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountDocument { 
    id: string;
    requestNotes?: string;
    status: AccountDocument.StatusEnum;
    subtype: AccountDocument.SubtypeEnum;
    type: AccountDocument.TypeEnum;
}
export namespace AccountDocument {
    export type StatusEnum = 'requested' | 'submitted' | 'rejected' | 'verified';
    export const StatusEnum = {
        REQUESTED: 'requested' as StatusEnum,
        SUBMITTED: 'submitted' as StatusEnum,
        REJECTED: 'rejected' as StatusEnum,
        VERIFIED: 'verified' as StatusEnum
    };
    export type SubtypeEnum = 'bank_letter_voided_check' | 'ein_letter' | 'articles_of_incorp' | 'business_license' | 'utility_bill' | 'processing_statement' | 'financial_statement' | '501c3' | 'tax_return' | 'invoice' | 'dl_passport' | 'dl_back' | 'bank_statement' | 'signed_merchant_agreement' | 'isv_cover_sheet' | 'business_model' | 'pricing_model' | 'website' | 'shopping_cart' | 'other';
    export const SubtypeEnum = {
        BANK_LETTER_VOIDED_CHECK: 'bank_letter_voided_check' as SubtypeEnum,
        EIN_LETTER: 'ein_letter' as SubtypeEnum,
        ARTICLES_OF_INCORP: 'articles_of_incorp' as SubtypeEnum,
        BUSINESS_LICENSE: 'business_license' as SubtypeEnum,
        UTILITY_BILL: 'utility_bill' as SubtypeEnum,
        PROCESSING_STATEMENT: 'processing_statement' as SubtypeEnum,
        FINANCIAL_STATEMENT: 'financial_statement' as SubtypeEnum,
        _501C3: '501c3' as SubtypeEnum,
        TAX_RETURN: 'tax_return' as SubtypeEnum,
        INVOICE: 'invoice' as SubtypeEnum,
        DL_PASSPORT: 'dl_passport' as SubtypeEnum,
        DL_BACK: 'dl_back' as SubtypeEnum,
        BANK_STATEMENT: 'bank_statement' as SubtypeEnum,
        SIGNED_MERCHANT_AGREEMENT: 'signed_merchant_agreement' as SubtypeEnum,
        ISV_COVER_SHEET: 'isv_cover_sheet' as SubtypeEnum,
        BUSINESS_MODEL: 'business_model' as SubtypeEnum,
        PRICING_MODEL: 'pricing_model' as SubtypeEnum,
        WEBSITE: 'website' as SubtypeEnum,
        SHOPPING_CART: 'shopping_cart' as SubtypeEnum,
        OTHER: 'other' as SubtypeEnum
    };
    export type TypeEnum = 'file' | 'written';
    export const TypeEnum = {
        FILE: 'file' as TypeEnum,
        WRITTEN: 'written' as TypeEnum
    };
}


