/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SuggestionOptions = 'accepted' | 'ignored';

export const SuggestionOptions = {
    ACCEPTED: 'accepted' as SuggestionOptions,
    IGNORED: 'ignored' as SuggestionOptions
};

