import { User } from '../../../../projects/tilled-api-client/src';

export class UserRoles {
  static DisplayText = new Map<User.RoleEnum, string>([
    [User.RoleEnum.ADMIN, 'Admin'],
    [User.RoleEnum.ANALYST, 'Analyst'],
    [User.RoleEnum.DEVELOPER, 'Developer'],
    [User.RoleEnum.OWNER, 'Owner'],
    [User.RoleEnum.VIEW_ONLY, 'View Only'],
    [User.RoleEnum.MERCHANT_OWNER, 'Merchant Owner'],
    [User.RoleEnum.MERCHANT_ADMIN, 'Merchant Admin'],
    [User.RoleEnum.RESELLER_OWNER, 'Reseller Owner'],
    [User.RoleEnum.RESELLER_ADMIN, 'Reseller Admin'],
    [User.RoleEnum.RESELLER_VIEW_ONLY, 'Reseller View Only'],
  ]);

  static Description = new Map<User.RoleEnum, string>([
    [User.RoleEnum.ADMIN, 'Full read write and edit access. Best for business owners and administrators'],
    [User.RoleEnum.ANALYST, 'View all data and reporting. Cannot add new accounts, users, or modify settings'],
    [User.RoleEnum.DEVELOPER, 'Full read and write access except user management'],
    [User.RoleEnum.OWNER, 'Owner of the business'],
    [User.RoleEnum.VIEW_ONLY, 'Read access only'],
    [User.RoleEnum.MERCHANT_OWNER, 'The owner of the merchant account'],
    [User.RoleEnum.MERCHANT_ADMIN, 'Best for merchant administrators'],

    // Unofficial role descriptions. Not used in the UI.
    // See: https://docs.google.com/spreadsheets/d/1c6GBpk6H1lvPQXryZuK6HSkdRtWuFppWCE0et8p0Ldo/edit?usp=sharing
    // [User.RoleEnum.RESELLER_OWNER, 'The owner of the reseller account'],
    // [User.RoleEnum.RESELLER_ADMIN, 'Full read write and edit access. Best for account owners and administrators'],
    // [User.RoleEnum.RESELLER_VIEW_ONLY, 'Full read access, excluding Reseller-level user and user invitations.'],
  ]);

  static KeysFromText = new Map<string, User.RoleEnum>([
    ['Admin', User.RoleEnum.ADMIN],
    ['Analyst', User.RoleEnum.ANALYST],
    ['Developer', User.RoleEnum.DEVELOPER],
    ['Owner', User.RoleEnum.OWNER],
    ['View Only', User.RoleEnum.VIEW_ONLY],
    ['Merchant Owner', User.RoleEnum.MERCHANT_OWNER],
    ['Merchant Admin', User.RoleEnum.MERCHANT_ADMIN],
    ['Reseller Owner', User.RoleEnum.RESELLER_OWNER],
    ['Reseller Admin', User.RoleEnum.RESELLER_ADMIN],
    ['Reseller View Only', User.RoleEnum.RESELLER_VIEW_ONLY],
  ]);
}
