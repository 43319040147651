/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttributesEsignature { 
    status: AttributesEsignature.StatusEnum;
}
export namespace AttributesEsignature {
    export type StatusEnum = 'sent' | 'completed';
    export const StatusEnum = {
        SENT: 'sent' as StatusEnum,
        COMPLETED: 'completed' as StatusEnum
    };
}


