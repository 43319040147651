/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Charge } from './charge';
import { PaymentIntent } from './paymentIntent';
import { BalanceTransaction } from './balanceTransaction';


export interface Refund { 
    account_id: string;
    /**
     * Amount (in the smallest currency unit) refunded.
     */
    amount: number;
    /**
     * The balance transaction that describes the impact of this refund on your account balance. Note: Multiple balance transactions may be associated with this resource and you can fetch them all with `/balance-transactions?source_id={id}`.
     * @deprecated
     */
    balance_transaction: BalanceTransaction;
    /**
     * The Charge that was refunded.
     */
    charge: Charge;
    /**
     * ID of the Charge that was refunded.
     */
    charge_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Error code explaining reason for refund failure.
     */
    failure_code?: Refund.FailureCodeEnum;
    /**
     * Message to user further explaining the reason for the refund failure.
     */
    failure_message?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: object;
    /**
     * The PaymentIntent that was refunded.
     */
    payment_intent: PaymentIntent;
    /**
     * ID of the PaymentIntent that was refunded.
     */
    payment_intent_id: string;
    /**
     * ID of the payment method that was refunded.
     */
    payment_method_id?: string;
    payment_provider_responses?: object;
    /**
     * Reason for the refund, either user-provided (`duplicate`, `fraudulent`, or `requested_by_customer`) or generated  by Tilled (`expired_uncaptured_charge`,`partial_capture`, or `reversal`)
     */
    reason?: Refund.ReasonEnum;
    /**
     * Status of the refund, one of `pending`, `succeeded`, `failed`, or `canceled`.
     */
    status: Refund.StatusEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace Refund {
    export type FailureCodeEnum = 'expired_or_canceled_card' | 'lost_or_stolen_card' | 'fraudulent' | 'risk_blocked' | 'declined' | 'proprietary_card_activity_regulations' | 'blacklisted_card' | 'not_permitted' | 'processing_error' | 'device_is_busy' | 'try_again_later';
    export const FailureCodeEnum = {
        EXPIRED_OR_CANCELED_CARD: 'expired_or_canceled_card' as FailureCodeEnum,
        LOST_OR_STOLEN_CARD: 'lost_or_stolen_card' as FailureCodeEnum,
        FRAUDULENT: 'fraudulent' as FailureCodeEnum,
        RISK_BLOCKED: 'risk_blocked' as FailureCodeEnum,
        DECLINED: 'declined' as FailureCodeEnum,
        PROPRIETARY_CARD_ACTIVITY_REGULATIONS: 'proprietary_card_activity_regulations' as FailureCodeEnum,
        BLACKLISTED_CARD: 'blacklisted_card' as FailureCodeEnum,
        NOT_PERMITTED: 'not_permitted' as FailureCodeEnum,
        PROCESSING_ERROR: 'processing_error' as FailureCodeEnum,
        DEVICE_IS_BUSY: 'device_is_busy' as FailureCodeEnum,
        TRY_AGAIN_LATER: 'try_again_later' as FailureCodeEnum
    };
    export type ReasonEnum = 'duplicate' | 'fraudulent' | 'requested_by_customer' | 'expired_uncaptured_charge' | 'partial_capture' | 'reversal';
    export const ReasonEnum = {
        DUPLICATE: 'duplicate' as ReasonEnum,
        FRAUDULENT: 'fraudulent' as ReasonEnum,
        REQUESTED_BY_CUSTOMER: 'requested_by_customer' as ReasonEnum,
        EXPIRED_UNCAPTURED_CHARGE: 'expired_uncaptured_charge' as ReasonEnum,
        PARTIAL_CAPTURE: 'partial_capture' as ReasonEnum,
        REVERSAL: 'reversal' as ReasonEnum
    };
    export type StatusEnum = 'canceled' | 'pending' | 'succeeded' | 'failed' | 'requires_action';
    export const StatusEnum = {
        CANCELED: 'canceled' as StatusEnum,
        PENDING: 'pending' as StatusEnum,
        SUCCEEDED: 'succeeded' as StatusEnum,
        FAILED: 'failed' as StatusEnum,
        REQUIRES_ACTION: 'requires_action' as StatusEnum
    };
}


