/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserInvitationCreateParams { 
    /**
     * Email address
     */
    email: string;
    /**
     * The type of email, if any, that should be sent to the new user. Defaults to `console_invitation` if one is not provided.  `merchant_application` \"You\'ve been invited by {partner name} to complete the setup of your payment processing account. To create your account and access your application follow the link below. {user invitation link}\"  `none` No email sent.  `console_invitation` - For **merchant** accounts: \"You have been invited to join *{{account name}}\'s* payment processing account with *{{partner name}}*.<br><br> *{user invitation hyperlink button}*<br><br> You will gain access to the team\'s Dashboard, including information about payments, payouts, and more. The invitation link will expire in 7 days.\" --- - For **partner** accounts: \"You have been invited to join *{{account name}}\'s* payment processing account with *{{parent name}}*.<br><br> *{user invitation hyperlink button}*<br><br> You will gain access to the team\'s Dashboard, including information about merchants, payments, payouts, and more. The invitation link will expire in 7 days.\" --- - For **reseller** accounts: \"You have been invited to join *{{account name}}\'s* payment processing account.<br><br> *{user invitation hyperlink button}*<br><br> You will gain access to the team\'s Dashboard, including information about your software platforms, merchants, payments, and more. The invitation link will expire in 7 days.\"
     */
    email_template?: UserInvitationCreateParams.EmailTemplateEnum;
    /**
     * The user\'s role, primarily used in the Tilled Dashboard. The roles have varied restrictions on the actions they can take.  `admin` Best for business owners and company administrators.  `developer` Best for developers or people primarily using the Tilled API.  `analyst` Best for people who need full access to Tilled data, but don\'t need to update business settings.  `view_only` Best for people who need to view Tilled data, but don\'t need to make any updates.
     */
    role: UserInvitationCreateParams.RoleEnum;
}
export namespace UserInvitationCreateParams {
    export type EmailTemplateEnum = 'merchant_application' | 'none' | 'console_invitation';
    export const EmailTemplateEnum = {
        MERCHANT_APPLICATION: 'merchant_application' as EmailTemplateEnum,
        NONE: 'none' as EmailTemplateEnum,
        CONSOLE_INVITATION: 'console_invitation' as EmailTemplateEnum
    };
    export type RoleEnum = 'admin' | 'developer' | 'analyst' | 'view_only' | 'merchant_owner' | 'merchant_admin' | 'reseller_owner' | 'reseller_admin' | 'reseller_view_only';
    export const RoleEnum = {
        ADMIN: 'admin' as RoleEnum,
        DEVELOPER: 'developer' as RoleEnum,
        ANALYST: 'analyst' as RoleEnum,
        VIEW_ONLY: 'view_only' as RoleEnum,
        MERCHANT_OWNER: 'merchant_owner' as RoleEnum,
        MERCHANT_ADMIN: 'merchant_admin' as RoleEnum,
        RESELLER_OWNER: 'reseller_owner' as RoleEnum,
        RESELLER_ADMIN: 'reseller_admin' as RoleEnum,
        RESELLER_VIEW_ONLY: 'reseller_view_only' as RoleEnum
    };
}


