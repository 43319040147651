/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProcessingVolume { 
    /**
     * Average transaction amount, in minor units for Card payments. (e.g. $100.00 = 10000 minor units)
     */
    average_transaction_amount_card?: number;
    /**
     * Average transaction amount, in minor units for Direct Debit payments. (e.g. $100.00 = 10000 minor units)
     */
    average_transaction_amount_debit?: number;
    /**
     * Three-letter ISO currency code, in lowercase.
     */
    currency: ProcessingVolume.CurrencyEnum;
    /**
     * Estimated high ticket amount, in minor units (e.g. $100.00 = 10000 minor units)
     */
    high_ticket_amount: number;
    /**
     * Estimated monthly processing volume amount of the business, in minor units. (e.g. $1,000,000.00 = 100,000,000 minor units)
     */
    monthly_processing_volume: number;
    /**
     * Estimated monthly number of transactions of the business.
     */
    monthly_transaction_count: number;
}
export namespace ProcessingVolume {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
}


