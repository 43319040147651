import { AsyncPipe, CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { ComponentBase } from 'app/core/componentBase';
import { PaysafeApplicationSteps, TsysApplicationSteps } from 'app/core/data/onboarding-types';
import { SelectorTypes } from 'app/core/data/selector-types';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { BrandingService } from 'app/core/services/branding.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import { AutocompleteComponent } from 'app/shared/autocomplete/autocomplete.component';
import { TilledButtonComponent } from 'app/shared/buttons/tilled-button.component';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { TilledInputComponent } from 'app/shared/form-fields/tilled-input/tilled-input.component';
import { RepresentativeRequirements } from 'app/shared/merchant-app-steps/representatives/representative-requirements';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { TilledHeadingH4Component } from 'app/shared/tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledHeadingH5Component } from 'app/shared/tilled-text/tilled-heading/tilled-heading-h5.component';
import { TilledLabelL1Component } from 'app/shared/tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from 'app/shared/tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from 'app/shared/tilled-text/tilled-paragraph/tilled-paragraph-p4.component';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isOlderThan, isValidYear } from 'app/shared/validators/dob.validator';
import { isEmail } from 'app/shared/validators/email.validator';
import { notEqualToList } from 'app/shared/validators/notEqualToList.validator';
import { isPhoneNumber } from 'app/shared/validators/phone.validator';
import { isPoBox } from 'app/shared/validators/po-box.validator';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscription, first, takeUntil } from 'rxjs';
import {
  OnboardingAddress,
  OnboardingApplication,
  OnboardingLegalEntity,
  PricingTemplate,
  PrincipalCreateParams,
} from '../../../../../../projects/tilled-api-client/src';
import RegionEnum = OnboardingLegalEntity.RegionEnum;

const badSSNs = [
  '000000000',
  '111111111',
  '222222222',
  '333333333',
  '444444444',
  '555555555',
  '666666666',
  '777777777',
  '888888888',
  '999999999',
  '123456789',
  '987654321',
];

interface PreviousBankruptcyData {
  previousBankruptcy: boolean;
  isPersonal: boolean; // else business
  fileDate?: string;
}

@Component({
  selector: 'add-representative-dialog',
  templateUrl: './add-representative-dialog.component.html',
  standalone: true,
  imports: [
    TilledHeadingH4Component, // Possibly Delete
    TilledHeadingH5Component, // Possibly Delete
    TilledLabelL1Component, // Possibly Delete
    TilledParagraphP3Component,
    TilledParagraphP4Component,
    TilledInputComponent,
    TilledSelectComponent,
    TilledButtonComponent,
    FormCardComponent,
    AutocompleteComponent,
    MatMenuModule,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDividerModule,
  ],
})
export class AddRepresentativeDialogComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  public title = 'Add Application signer';
  public saveText = 'Add Application signer';

  public representativesFormArray: FormArray = new FormArray([]);
  public userInviteForm: FormGroup;
  public accountId: string = '';
  public hasTsysProvider: boolean = false;
  public multipleOwners: boolean = false;
  public hideSSN: boolean = false;
  public hideOwnershipQuestion: boolean = false;
  public disabled: boolean = false;
  public requirements: string[];
  public isCanadian: boolean;
  public forConsole: boolean = false;
  public disabled$: Observable<boolean> = null;
  public saveApp$: Observable<string> = null;
  public checkUnsavedApp$: Observable<string> = null;
  public resetApp$: Observable<boolean> = null;
  public stepNumber: number;
  public currentPrincipal: PrincipalCreateParams;
  public ownsMoreThan25Percent: boolean = false;
  public busOwnerStep: boolean;
  public merchantApp: OnboardingApplication;
  public businessEntityType: any;
  public phoneCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.CountryToPhoneCode).map(
    ([label, value]) => ({ label, value }),
  );
  public selectedPhoneCode: string;
  public supportPhone: string = '';
  public stateCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.stateAndProvinceMap).map(
    ([label, value]) => ({ label, value }),
  );
  public jobTitleMap: { label: string; value: string }[] = SelectorTypes.jobTitleArray.map((code) => ({
    label: code.desc,
    value: code.name,
  }));

  private subscriptions: Subscription[] = [];
  private representativeRequirements: RepresentativeRequirements = new RepresentativeRequirements();
  private stateAndProvinceMap = SelectorTypes.stateAndProvinceMap;
  private principals: PrincipalCreateParams[];
  private _controlProngIndex$ = new BehaviorSubject<number>(-1);
  public controlProngIndex$ = this._controlProngIndex$.asObservable();
  private _applicantIndex$ = new BehaviorSubject<number>(-1);
  public applicantIndex$ = this._applicantIndex$.asObservable();
  private _showPreviousBankruptcy$ = new BehaviorSubject<boolean>(false);
  public showPreviousBankruptcy$ = this._showPreviousBankruptcy$.asObservable();

  public isWhiteLabel$: Observable<boolean>;
  private closeTimeout: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: AddRepresentativeDialogData,
    public dialogRef: MatDialogRef<AddRepresentativeDialogComponent>,
    private _merchantAppService: MerchantAppService,
    private _usersAppService: UsersAppService,
    private _alertService: AlertService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _matDialog: MatDialog,
    private _brandingService: BrandingService,
  ) {
    super();
    this._merchantAppService.hasTsysProvider$.subscribe((hasTsysProvider) => {
      this.hasTsysProvider = hasTsysProvider;
    });
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
  }

  ngOnInit(): void {
    this.accountId = this._data.accountId;
    this.stepNumber = this._data.stepNumber;
    this.forConsole = this._data.forConsole;
    this.disabled$ = this._data.disabled$;
    this.saveApp$ = this._data.saveApp$;
    this.checkUnsavedApp$ = this._data.checkUnsavedApp$;
    this.resetApp$ = this._data.resetApp$;
    this.currentPrincipal = this._data.currentPrincipal;

    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.representativesFormArray.disable();
          } else {
            this.representativesFormArray.enable();
          }
        }),
      );
    }
    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
    this.selectedPhoneCode = this.selectedPhoneCode ? this.selectedPhoneCode : this.phoneCodeMap[0].value;

    this._merchantAppService.hasTsysProvider$.subscribe((hasTsys) => {
      this.hasTsysProvider = hasTsys;
    });
    this._merchantAppService.multipleOwners$.subscribe((multipleOwners) => {
      this.multipleOwners = multipleOwners;
    });
    this._merchantAppService.updateProviderData();
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onIsControlProng(index: number): void {
    const isControlProng = this.representativesFormArray.value[index].isControlProng;
    if (isControlProng) {
      for (let i = 0; i < this.representativesFormArray.value.length; i++) {
        this.representativesFormArray.controls[i].patchValue({ previousBankruptcy: false });
        this.representativesFormArray.controls[i].patchValue({ isPersonal: false });
        this.representativesFormArray.controls[i].patchValue({ fileDate: null });
        if (i === index) {
          this._controlProngIndex$.next(i);
          this.onPreviousBankruptcy(i);
          continue;
        }
        this.representativesFormArray.controls[i].patchValue({ isControlProng: false });
      }
    } else {
      // reset previous bankruptcy
      this.representativesFormArray.controls[index].patchValue({ previousBankruptcy: false });
      this.representativesFormArray.controls[index].patchValue({ isPersonal: false });
      this.representativesFormArray.controls[index].patchValue({ fileDate: null });
      this._controlProngIndex$.next(-1);
    }
  }
  onPreviousBankruptcy(index: number): void {
    const previousBankruptcy = this.representativesFormArray.value[index].previousBankruptcy;
    if (previousBankruptcy) {
      this._showPreviousBankruptcy$.next(true);
    } else {
      this._showPreviousBankruptcy$.next(false);
    }
  }

  public onOwnsMoreThan25Percent(value: boolean): void {
    if (this.busOwnerStep) {
      return;
    }
    this.ownsMoreThan25Percent = value;
    this.representativesFormArray.controls.forEach((group) => {
      if (group?.get('id')?.value === this.currentPrincipal?.id || !this.currentPrincipal) {
        group.get('percentageShareholding').setValue(0);
        group.get('percentageShareholding').addValidators([this.ownsMoreThan25Percent ? Validators.required : null]);
      }
    });
  }

  public scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public scrollToError(): void {
    const firstElementWithError =
      document.querySelector('.mat-form-field-invalid') ||
      document.querySelector('mat-checkbox  tilled-paragraph-p4.text-warn');
    this.scrollTo(firstElementWithError);
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  onContinueClicked(accountId?: string): void {
    if (this.forConsole) {
      accountId = this.accountId;
    }

    const index = this.currentPrincipal
      ? this.representativesFormArray.controls.findIndex(
          (control) => control.get('id').value === this.currentPrincipal?.id || !this.currentPrincipal,
        )
      : 0;

    let totalOwnership = 0;
    for (const principal of this.representativesFormArray.value) {
      totalOwnership += parseInt(principal.percentageShareholding, 10);
    }

    if (totalOwnership > 100) {
      const message: TilledAlert = {
        message: 'The total ownership percent of all application signers must not exceed 100%',
        title: 'Representatives',
        type: 'error',
      };
      this._alertService.showAlert(message);
      this.representativesFormArray.controls[0].get('percentageShareholding').setErrors({ exists: true });
    }

    this.representativesFormArray.controls[index].markAllAsTouched();

    if (this.representativesFormArray.controls[index].invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }

    // if the phone number is not prefixed with a country code, prefix it with the selected country code (US by default)
    this.representativesFormArray.controls.forEach((control) => {
      if (control.get('phone').value && !control.get('phone').value.startsWith('+')) {
        control.get('phone').patchValue(`${this.selectedPhoneCode}${control.get('phone').value}`);
      }
    });

    this.principals = [];
    for (let i = 0; i < this.representativesFormArray.value.length; i++) {
      const state = this.representativesFormArray.value[i].currentState
        ? [...this.stateAndProvinceMap].find(
            ([key, val]) => val === this.representativesFormArray.value[i].currentState,
          )[0]
        : null;
      this.principals[i] = {
        id: this.representativesFormArray.value[i].id,
        is_applicant: this.representativesFormArray.value[i].isApplicant,
        is_control_prong: this.representativesFormArray.value[i].isControlProng,
        is_signatory: this.representativesFormArray.value[i].isApplicant,
        receive_1099k_via_email:
          this.representativesFormArray.value[i].taxFormViaEmail ??
          // @ts-ignore
          this.merchantApp?.legal_entity?.receive_1099k_via_email ?? // check for receive_1099k_via_email on the legal entity for legacy apps
          null,
        first_name: this.representativesFormArray.value[i].firstName,
        last_name: this.representativesFormArray.value[i].lastName,
        date_of_birth: moment(this.representativesFormArray.value[i].dob, 'MM-DD-YYYY').toISOString(),
        id_number:
          this.representativesFormArray.value[i].ssn !== '' ? this.representativesFormArray.value[i].ssn : null,
        job_title: this.representativesFormArray.value[i].jobTitle,
        percent_ownership: parseInt(this.representativesFormArray.value[i].percentageShareholding, 10) || 0,
        email: this.representativesFormArray.value[i].email,
        phone: this.representativesFormArray.value[i].phone,
        address: {
          street: this.representativesFormArray.value[i].currentStreet,
          street2: this.representativesFormArray.value[i].currentStreet2,
          city: this.representativesFormArray.value[i].currentCity,
          state: state,
          postal_code: this.representativesFormArray.value[i].currentZip,
          country: SelectorTypes.getCountryFromState(state),
        },
        previous_address: undefined,
      };

      if (this.isCanadian) {
        this.principals[i].years_at_address = parseInt(
          this.representativesFormArray.value[i].yearsAtCurrentAddress,
          10,
        );
      }

      if (this.representativesFormArray.value[i].yearsAtCurrentAddress <= 3 && this.isCanadian) {
        const previousState = this.representativesFormArray.value[i].previousState
          ? [...this.stateAndProvinceMap].find(
              ([key, val]) => val === this.representativesFormArray.value[i].previousState,
            )[0]
          : null;
        this.principals[i].previous_address = {
          street: this.representativesFormArray.value[i].previousStreet,
          street2: this.representativesFormArray.value[i].previousStreet2,
          city: this.representativesFormArray.value[i].previousCity,
          state: previousState,
          postal_code: this.representativesFormArray.value[i].previousZip,
          country: SelectorTypes.getCountryFromState(previousState),
        };
        this.principals[i].years_at_previous_address = parseInt(
          this.representativesFormArray.value[i].yearsAtPreviousAddress,
          10,
        );
      }
      this.representativesFormArray.controls.forEach((control, i) => {
        if (control.value.isApplicant) {
          // Guard against missing principals array or index
          if (!this.merchantApp?.legal_entity?.principals || this.merchantApp.legal_entity.principals.length <= i) {
            console.error(
              `Skipping update for index ${i}: Principals array is undefined or too short.`,
              this.merchantApp.legal_entity.principals,
            );
            return;
          }

          // Safely update the principal's receive_1099k_via_email field
          this.merchantApp.legal_entity.principals[i].receive_1099k_via_email =
            control.value.taxFormViaEmail ?? this.representativesFormArray.value[i].taxFormViaEmail ?? false;
        }
      });
    }
    if (!this.merchantApp.legal_entity.principals) {
      this.merchantApp.legal_entity.principals = [];
    }
    this.merchantApp.legal_entity.principals = this.principals;

    if (this.hasTsysProvider) {
      let applicant: any;
      this.representativesFormArray.value.forEach((rep) => {
        if (rep.isApplicant) {
          applicant = rep;
        }
      });
      let merchantUsersAndInvitations = [];
      this._usersAppService.users$.pipe(first()).subscribe((users) => {
        users?.forEach((user) => {
          merchantUsersAndInvitations.push(user);
        });
      });
      this._usersAppService.userInvitations$.pipe(first()).subscribe((invitations) => {
        invitations?.forEach((invitation) => {
          merchantUsersAndInvitations.push(invitation);
        });
      });
    }

    this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber, accountId);
    this.dialogRef.close(this.merchantApp);
  }

  public getPrincipalFormGroup(
    data: PrincipalCreateParams,
    bankruptcy: PreviousBankruptcyData,
    index: number,
  ): FormGroup {
    data = data || ({} as PrincipalCreateParams);

    let setToPrimaryApplicant = false;
    let ownership = data.percent_ownership;
    const primaryApplicant =
      this.representativesFormArray.value.find((rep) => rep.isApplicant) ||
      this.principals?.find((rep) => rep.is_applicant);

    if (this.busOwnerStep && primaryApplicant && primaryApplicant?.id === data?.id) {
      setToPrimaryApplicant = true;
    }

    if (!this.busOwnerStep && primaryApplicant?.id === data?.id) {
      setToPrimaryApplicant = true;

      // Used to set ownership for reps where ownership question is hidden (sole proprietorship, non-profit, charity, and government)
      if (this.hideOwnershipQuestion) {
        ownership =
          this.merchantApp?.legal_entity?.structure == OnboardingLegalEntity.StructureEnum.SOLE_PROPRIETORSHIP
            ? 100
            : 0;
      }
    }

    if (data.is_applicant) {
      this._applicantIndex$.next(index);
    }
    if (data.is_control_prong) {
      this._controlProngIndex$.next(index);
      if (bankruptcy.previousBankruptcy) {
        this._showPreviousBankruptcy$.next(true);
      }
    }

    if (this.currentPrincipal?.percent_ownership > 0 && !this.hideOwnershipQuestion && !this.busOwnerStep) {
      this.ownsMoreThan25Percent = true;
    } else {
      this.ownsMoreThan25Percent = false;
    }

    return new FormGroup({
      id: new FormControl<string | null>(data.id || null),
      isApplicant: new FormControl<boolean>(setToPrimaryApplicant),
      isControlProng: new FormControl<boolean>(setToPrimaryApplicant || false),
      firstName: new FormControl<string | null>(data.first_name || null, [Validators.required]),
      lastName: new FormControl<string | null>(data.last_name || null, [Validators.required]),
      dob: new FormControl<string | null>(data.date_of_birth ? moment(data.date_of_birth).format('MM/DD/YYYY') : null, [
        Validators.required,
        isOlderThan(18),
        isValidYear(1900),
      ]),
      ssn: new FormControl<string | null>(
        data.id_number || null,
        !this.hideSSN ? [Validators.required, notEqualToList(badSSNs)] : [],
      ),
      jobTitle: new FormControl<string | null>(data.job_title || null, [Validators.required]),
      phone: new FormControl<string | null>(data.phone || null, [Validators.required, isPhoneNumber('US')]),
      phoneCode: new FormControl<string | null>(this.selectedPhoneCode || null),
      percentageShareholding: new FormControl<number | null>(ownership, [Validators.min(0), Validators.max(100)]),
      currentStreet: new FormControl<string | null>(data.address?.street || null, [Validators.required, isPoBox()]),
      currentStreet2: new FormControl<string | null>(data.address?.street2 || null),
      currentCity: new FormControl<string | null>(data.address?.city || null, [Validators.required]),
      currentState: new FormControl<string | null>(SelectorTypes.stateAndProvinceMap.get(data.address?.state) || null, [
        Validators.required,
      ]),
      currentZip: new FormControl<string | null>(data.address?.postal_code || null, [Validators.required]),
      currentCountry: new FormControl<string | null>(data.address?.country || null),
      yearsAtCurrentAddress: new FormControl<number | null>(data.years_at_address || null),
      previousStreet: new FormControl<string | null>(data.previous_address?.street || null),
      previousStreet2: new FormControl<string | null>(data.previous_address?.street2 || null),
      previousCity: new FormControl<string | null>(data.previous_address?.city || null),
      previousState: new FormControl<string | null>(
        SelectorTypes.stateAndProvinceMap.get(data.previous_address?.state) || null,
      ),
      previousZip: new FormControl<string | null>(data.previous_address?.postal_code || null),
      previousCountry: new FormControl<string | null>(data.previous_address?.country || null),
      yearsAtPreviousAddress: new FormControl<number | null>(data.years_at_previous_address || null),
      email: new FormControl<string | null>(data.email || null, [Validators.required, isEmail()]),
      taxFormViaEmail: new FormControl<boolean | null>(data.receive_1099k_via_email || false),
      previousBankruptcy: new FormControl<boolean>(bankruptcy?.previousBankruptcy || false),
      isPersonal: new FormControl<boolean | null>(bankruptcy?.isPersonal ? true : false),
      fileDate: new FormControl<string | null>(
        bankruptcy?.fileDate ? moment(bankruptcy?.fileDate).format('MM/DD/YYYY') : null,
        [isValidYear(1600)],
      ),
    });
  }

  public removePrincipal(): void {
    // show dialog with confirmation
    const dialogRef = this._matDialog.open(FuseConfirmationDialogComponent, {
      data: {
        title: this.title.replace('Add', 'Delete'),
        message:
          'Are you sure you want to delete this' + (this.busOwnerStep ? ' business owner?' : ' application signer?'),
        icon: {
          show: false,
        },
        actions: {
          confirm: {
            show: true,
            label: 'Delete',
            color: 'warn',
          },
          cancel: {
            show: true,
            label: 'Cancel',
            outline: true,
          },
        },
        dismissible: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        if (result && result === 'confirmed') {
          this.representativesFormArray.controls.forEach((group) => {
            if (group?.get('id')?.value === this.currentPrincipal?.id || !this.currentPrincipal) {
              this.merchantApp.legal_entity.principals = this.merchantApp.legal_entity.principals.filter(
                (principal) => principal.id !== this.currentPrincipal.id,
              );
            }
          });
          this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber);
          this.dialogRef.close(this.merchantApp);
          setTimeout(() => {
            const message: TilledAlert = {
              message:
                `${this.currentPrincipal?.first_name ?? ''} ${this.currentPrincipal?.last_name ?? ''}`.trim() +
                ' has been deleted.',
              title: 'Business owner deleted',
              type: 'success',
              timer: 5000,
            };
            this._alertService.showAlert(message);
          }, 500);
        } else {
          return;
        }
      });
  }

  public setPrincipalAddress(address: OnboardingAddress, index: number, addressType: string): void {
    if (addressType === 'current') {
      this.representativesFormArray.controls[index].patchValue({
        currentStreet: address.street,
        currentCity: address.city,
        currentState: SelectorTypes.stateAndProvinceMap.get(address.state),
        currentZip: address.postal_code,
      });
    } else {
      this.representativesFormArray.controls[index].patchValue({
        previousStreet: address.street,
        previousCity: address.city,
        previousState: SelectorTypes.stateAndProvinceMap.get(address.state),
        previousZip: address.postal_code,
      });
    }
    this.representativesFormArray.controls[index].markAsTouched();
  }

  private isAppUnsaved(): boolean {
    let unchanged: number = 1;
    if (!this.principals) {
      for (const field in this.representativesFormArray.value[0]) {
        // check if there is any data for first bis rep in form
        const value = this.representativesFormArray.value[0][field];
        if (value) {
          return true;
        }
      }
      return false;
    }
    if (this.principals.length != this.representativesFormArray.length) {
      return true;
    }
    for (let i = 0; i < this.representativesFormArray.length; i++) {
      for (const field in this.representativesFormArray.value[i]) {
        if (field) {
          let value = this.representativesFormArray.controls[i].get(field).value;
          if (value === '') {
            this.representativesFormArray.controls[i].get(field).setValue(null);
          }
        }
      }
    }

    for (let i = 0; i < this.representativesFormArray.value.length; i++) {
      const state = this.representativesFormArray.value[i].currentState
        ? [...this.stateAndProvinceMap].find(
            ([key, val]) => val === this.representativesFormArray.value[i].currentState,
          )[0]
        : null;
      const previousState = this.representativesFormArray.value[i].previousState
        ? [...this.stateAndProvinceMap].find(
            ([key, val]) => val === this.representativesFormArray.value[i].previousState,
          )[0]
        : null;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].id, this.principals[i]?.id) ? 1 : 0;
      unchanged *= this.representativesFormArray.value[i].isApplicant === this.principals[i]?.is_applicant ? 1 : 0;
      unchanged *=
        this.representativesFormArray.value[i].isControlProng === this.principals[i]?.is_control_prong ? 1 : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].firstName, this.principals[i]?.first_name)
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].lastName, this.principals[i]?.last_name)
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].dob,
        this.principals[i]?.date_of_birth
          ? moment(this.principals[i]?.date_of_birth).format('MM/DD/YYYY').toString()
          : null,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].ssn, this.principals[i]?.id_number)
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].jobTitle, this.principals[i]?.job_title)
        ? 1
        : 0;
      unchanged *=
        (this.representativesFormArray.value[i].percentageShareholding
          ? parseInt(this.representativesFormArray.value[i].percentageShareholding, 10)
          : null) == this.principals[i]?.percent_ownership
          ? 1
          : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].email, this.principals[i]?.email) ? 1 : 0;
      unchanged *= _compareTwoStrings(this.representativesFormArray.value[i].phone, this.principals[i]?.phone) ? 1 : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentStreet,
        this.principals[i]?.address?.street,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentStreet2,
        this.principals[i]?.address?.street2,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentCity,
        this.principals[i]?.address?.city,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(state, this.principals[i]?.address?.state) ? 1 : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].currentZip,
        this.principals[i]?.address?.postal_code,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(SelectorTypes.getCountryFromState(state), this.principals[i]?.address?.country)
        ? 1
        : 0;
      unchanged *=
        (this.representativesFormArray.value[i].yearsAtCurrentAddress
          ? parseInt(this.representativesFormArray.value[i].yearsAtCurrentAddress, 10)
          : null) == this.principals[i]?.years_at_address
          ? 1
          : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousStreet,
        this.principals[i]?.previous_address?.street,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousStreet2,
        this.principals[i]?.previous_address?.street2,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousCity,
        this.principals[i]?.previous_address?.city,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(previousState, this.principals[i]?.previous_address?.state) ? 1 : 0;
      unchanged *= _compareTwoStrings(
        this.representativesFormArray.value[i].previousZip,
        this.principals[i]?.previous_address?.postal_code,
      )
        ? 1
        : 0;
      unchanged *= _compareTwoStrings(
        SelectorTypes.getCountryFromState(previousState),
        this.principals[i]?.previous_address?.country,
      )
        ? 1
        : 0;
      unchanged *=
        (this.representativesFormArray.value[i].yearsAtPreviousAddress
          ? parseInt(this.representativesFormArray.value[i].yearsAtPreviousAddress, 10)
          : null) == this.principals[i]?.years_at_previous_address
          ? 1
          : 0;

      if (!unchanged) {
        break;
      }
    }

    return !unchanged;
  }
  private resetApplication(): void {
    this.representativesFormArray = new FormArray([]);
    this.stepConfig(this.stepNumber);
    switch (this.merchantApp?.legal_entity?.structure) {
      case OnboardingLegalEntity.StructureEnum.GOVERNMENT:
        if (!this.hasTsysProvider) {
          this.hideSSN = true;
        }
      case OnboardingLegalEntity.StructureEnum.SOLE_PROPRIETORSHIP:
      case OnboardingLegalEntity.StructureEnum.CHARITY:
        this.hideOwnershipQuestion = true;
        break;
      default:
        break;
    }

    this.businessEntityType = this.representativeRequirements.requirements.find(
      (entityType) => entityType.type === this.merchantApp.legal_entity?.structure,
    );

    if (this.businessEntityType) {
      this.requirements =
        this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.USD
          ? this.businessEntityType.usReq
          : this.businessEntityType.caReq;
    }

    this.principals = this.merchantApp.legal_entity?.principals;
    const bankruptcy: PreviousBankruptcyData = {
      previousBankruptcy: this.merchantApp.legal_entity?.previous_bankruptcy,
      isPersonal: this.merchantApp.legal_entity?.personal_file_date != null,
      fileDate: this.merchantApp.legal_entity?.personal_file_date ?? this.merchantApp.legal_entity?.business_file_date,
    };
    this.principals = this.merchantApp.legal_entity?.principals;
    this.principals?.forEach((principal, index) =>
      this.representativesFormArray.push(this.getPrincipalFormGroup(principal, bankruptcy, index)),
    );

    if (!this.principals || this.principals.length === 0) {
      this.representativesFormArray.push(this.getPrincipalFormGroup(null, bankruptcy, 0));
    }

    if (!this.currentPrincipal && this.principals?.length > 0) {
      this.representativesFormArray.push(this.getPrincipalFormGroup(null, bankruptcy, this.principals?.length + 1));
    }

    if (this.hasTsysProvider && !this.busOwnerStep) {
      this.representativesFormArray.controls.forEach((group) => {
        if (group?.get('id')?.value === this.currentPrincipal?.id || !this.currentPrincipal) {
          this.representativesFormArray.controls[this.representativesFormArray.controls.indexOf(group)]
            .get('isApplicant')
            .setValidators(Validators.requiredTrue);
        }
      });
    }

    if (this.busOwnerStep) {
      this.representativesFormArray.controls.forEach((group) => {
        if (group?.get('id')?.value === this.currentPrincipal?.id || !this.currentPrincipal) {
          group.get('percentageShareholding').addValidators([Validators.required]);
        }
      });
    }

    this.isCanadian = this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.CAD;
    if (this.isCanadian) {
      this.representativesFormArray.controls.forEach((group) => {
        group.get('ssn').clearValidators();
        group.get('ssn').updateValueAndValidity();
      });
    }

    this._changeDetectorRef.markForCheck();
  }
  openMenu(): void {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
    this.menuTrigger.openMenu();
  }

  closeMenu(): void {
    this.closeTimeout = setTimeout(() => {
      this.menuTrigger.closeMenu();
    }, 200);
  }

  public stepConfig(step: number): void {
    switch (step) {
      case PaysafeApplicationSteps.APPLICATION_SIGNER_SUB_STEP:
      case TsysApplicationSteps.APPLICATION_SIGNER_SUB_STEP:
        this.title = 'Add Application signer';
        this.saveText = 'Add Application signer';
        this.busOwnerStep = false;
        break;
      case PaysafeApplicationSteps.BUSINESS_OWNERS_SUB_STEP:
      case TsysApplicationSteps.BUSINESS_OWNERS_SUB_STEP:
        this.title = 'Add Business Owner';
        this.saveText = 'Add Business Owner';
        this.busOwnerStep = true;
        this.ownsMoreThan25Percent = true;
        break;
    }
  }
}
export interface AddRepresentativeDialogData {
  accountId: string;
  forConsole: boolean;
  stepNumber: number;
  disabled$: Observable<boolean>;
  saveApp$: Observable<string>;
  checkUnsavedApp$: Observable<string>;
  resetApp$: Observable<boolean>;
  currentPrincipal: PrincipalCreateParams;
}
