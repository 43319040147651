import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrandingService } from 'app/core/services/branding.service';
import { Observable } from 'rxjs';
import { TilledParagraphP4Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';

export enum ChipColorClass {
  SOLID_GREEN = 'solid_green',
  OPAQUE_GREEN = 'opaque_green',
  SOLID_YELLOW = 'solid_yellow',
  OPAQUE_YELLOW = 'opaque_yellow',
  SOLID_RED = 'solid_red',
  OPAQUE_RED = 'opaque_red',
  SOLID_GRAY = 'solid_gray',
  OPAQUE_GRAY = 'opaque_gray',
  SOLID_BLUE = 'solid_blue',
  OPAQUE_BLUE = 'opaque_blue',
  SOLID_ACCENT = 'solid_accent',
  OPAQUE_ACCENT = 'opaque_accent',
  SOLID_ORANGE = 'solid_orange',
  OPAQUE_ORANGE = 'opaque_orange',
  SOLID_PURPLE = 'solid_purple',
  OPAQUE_PURPLE = 'opaque_purple',
  SOLID_NEUTRAL = 'solid_neutral',
  OPAQUE_NEUTRAL = 'opaque_neutral',
}

export interface TilledChipConfig {
  text: string;
  toolTip: string;
  color: ChipColorClass;
  topNote?: string;
  capabilities?: string[];
  icon?: string;
  requestedDocuments?: string[];
  submittedDocuments?: string[];
  bottomNote?: string;
}

@Component({
  selector: 'tilled-chip',
  templateUrl: './tilled-chip.component.html',
  styleUrls: ['./tilled-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatChipsModule, MatTooltipModule, MatIconModule, MatMenuModule, TilledParagraphP4Component, CommonModule],
})
export class TilledChipComponent implements OnInit, OnChanges {
  @Input() chipConfig: TilledChipConfig;
  @ViewChild(MatMenuTrigger) documentsMenuTrigger: MatMenuTrigger;
  private menuOpenTimeout: any;

  public displayStatus: string;
  public displayStatusIcon: string;
  public colorClass: string;
  public tooltipText: string;
  public hasDocuments: boolean = false;
  public icon: string;
  public capabilities: string[];
  public requestedDocs: string[];
  public submittedDocs: string[];
  public topNote: string;
  public bottomNote: string;
  public hasMatMenu: boolean = false;
  public solidColor: boolean = false;
  public isWhiteLabel$: Observable<boolean>;

  constructor(private _brandingService: BrandingService) {
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
  }

  ngOnInit(): void {
    this.computeDisplayStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.computeDisplayStatus();
  }

  private computeDisplayStatus(): void {
    if (this.chipConfig?.color.includes('solid')) {
      this.solidColor = true;
    } else {
      this.solidColor = false;
    }
    this.colorClass = this.chipConfig?.color as string;
    this.displayStatus = this.chipConfig?.text;
    this.tooltipText = this.chipConfig?.toolTip;
    this.hasDocuments =
      this.chipConfig?.requestedDocuments?.length > 0 || this.chipConfig?.submittedDocuments?.length > 0;
    this.icon = this.chipConfig?.icon;
    this.capabilities = this.chipConfig?.capabilities;
    this.requestedDocs = this.chipConfig?.requestedDocuments;
    this.submittedDocs = this.chipConfig?.submittedDocuments;
    this.topNote = this.chipConfig?.topNote;
    this.bottomNote = this.chipConfig?.bottomNote;

    this.hasMatMenu =
      this.capabilities != null ||
      this.requestedDocs != null ||
      this.submittedDocs != null ||
      this.topNote != null ||
      this.bottomNote != null;
  }

  openDocumentsMenu(trigger) {
    if (this.menuOpenTimeout) {
      clearTimeout(this.menuOpenTimeout);
    }
    trigger.openMenu();
  }

  closeDocumentsMenu(trigger) {
    this.menuOpenTimeout = setTimeout(() => {
      trigger.closeMenu();
    }, 100);
  }
}
