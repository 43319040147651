/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckoutSessionLineItem } from './checkoutSessionLineItem';
import { CustomerDetails } from './customerDetails';


export interface CheckoutSession { 
    /**
     * The ID of the account for this checkout session.
     */
    account_id: string;
    /**
     * Total of all items, in currency minor units.
     */
    amount_total: number;
    /**
     * If set, checkout will display a back button and customers will be directed to this URL if they decide to cancel payment and return to your website.
     */
    cancel_url?: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: CheckoutSession.CurrencyEnum;
    /**
     * The customer details.
     */
    customer_details?: CustomerDetails;
    /**
     * The ID of the customer for this checkout session.
     */
    customer_id?: string;
    /**
     * The due date for the checkout session. After this date the customer will no longer be able to complete the checkout session.
     */
    expires_at?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * A list of items the customer is purchasing. The maximum is 100 line items, however it is recommended to consolidate line items if there are more than a few dozen.
     */
    line_items: Array<CheckoutSessionLineItem>;
    /**
     * Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format. Individual keys can be unset by posting an empty value (i.e. `\'\'` or `null`) to them. All keys can be unset by posting an empty value (i.e. `{}` or `null`) to `metadata`.\"
     */
    metadata?: { [key: string]: string; };
    /**
     * The ID of the payment intent for checkout session.
     */
    payment_intent_id: string;
    /**
     * A list of the types of payment methods (e.g. card) this checkout session is allowed to accept.
     */
    payment_method_types: Array<CheckoutSession.PaymentMethodTypesEnum>;
    /**
     * The status of the checkout session, one of `open`, `complete` or `expired`.  `open` The checkout session is still in progress. Payment processing has not started.  `complete` The checkout session is complete. Payment processing may still be in progress.  `expired` The checkout session has expired. No further processing will occur.
     */
    status: CheckoutSession.StatusEnum;
    /**
     * The URL to which we should send customers when payment is complete. It is recommended that you supply this value, but if none is provided, a generic success landing page will be used.
     */
    success_url?: string;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * The URL to the checkout session. Redirect customers to this URL to take them to checkout.
     */
    url: string;
}
export namespace CheckoutSession {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type PaymentMethodTypesEnum = 'card' | 'ach_debit' | 'eft_debit' | 'card_present';
    export const PaymentMethodTypesEnum = {
        CARD: 'card' as PaymentMethodTypesEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypesEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypesEnum,
        CARD_PRESENT: 'card_present' as PaymentMethodTypesEnum
    };
    export type StatusEnum = 'open' | 'complete' | 'expired';
    export const StatusEnum = {
        OPEN: 'open' as StatusEnum,
        COMPLETE: 'complete' as StatusEnum,
        EXPIRED: 'expired' as StatusEnum
    };
}


