import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { InternalAccount, InternalService, ProductCode } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { TilledSearchComponent } from 'app/shared/tilled-search/tilled-search.component';
import { takeUntil } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    TilledSearchComponent,
    CommonModule,
  ],
})
export class GlobalSearchComponent extends ComponentBase implements OnInit {
  public globalSearchForm: FormGroup;
  //represents prefixes currently allowed for any user type
  public allowedPrefixes: string[] = ['ch', 'pi', 'po', 're', 'term', 'dp'];
  //represents prefixes only allowed for merchant users
  public allowedMerchantPrefixes: string[] = ['cus'];
  //represents prefixes only allowed for partner users
  public allowedPartnerPrefixes: string[] = ['acct'];
  public isMerchant: boolean = false;
  public isPartner: boolean = false;
  public tooltip = 'Placeholder';
  public partnerTooltip = 'Search by account ID, payment ID, payout ID, or dispute ID';
  public merchantTooltip = 'Search by payment ID, payout ID, dispute ID, or customer ID';
  public partnerCPTooltip = 'Search by account ID, payment ID, payout ID, dispute ID, or terminal ID';
  public merchantCPTooltip = 'Search by payment ID, payout ID, dispute ID, terminal ID, or customer ID';
  public cardPresentCapability = false;
  constructor(
    private _alertService: AlertService,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _internalService: InternalService,
    private _router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.globalSearchForm = this._formBuilder.group({
      globalSearchInput: new FormControl<string | null>(null),
    });
    this.isMerchant = this._authService.isMerchantUser();
    this.isPartner = this._authService.isPartnerUser();
    if (this.isMerchant) {
      this.tooltip = this.merchantTooltip;
      this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
        this.tooltip = this.merchantTooltip;
        for (const capability of account?.capabilities) {
          if (capability.product_code?.payment_method_type === ProductCode.PaymentMethodTypeEnum.CARD_PRESENT) {
            this.tooltip = this.merchantCPTooltip;
          }
        }
      });
    } else if (this.isPartner) {
      this.tooltip = this.partnerTooltip;
      this._internalService
        .internalGetAccountProcessingCapabilities({
          tilledAccount: AuthService.getCurrentAccountId(),
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result) => {
          if (result.card_present.length > 0) {
            this.tooltip = this.partnerCPTooltip;
          } else {
            this.tooltip = this.partnerTooltip;
          }
        });
    } else {
      //TODO as part of reseller dashboard how to know the tooltip value to use, for now default to the partner tooltip
      this.tooltip = this.partnerTooltip;
    }
  }

  onEnter(): void {
    const inputValue = this.globalSearchForm.get('globalSearchInput').value;
    if (!this.validatePrefix(inputValue.split('_')[0])) {
      const message: TilledAlert = {
        message: 'Unique ID format is invalid',
        title: 'Invalid format',
        type: 'error',
        bottom: false,
        timer: 8000,
      };
      this._alertService.showAlert(message);
      return;
    }

    this._internalService
      .searchById({
        tilledAccount: AuthService.getCurrentAccountId(),
        id: inputValue,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.routeResult(res.account_id, res.entity_id);
        },
        error: (err) => {
          this.idNotFound();
        },
      });
  }

  routeResult(account_id: string, entity_id: string): void {
    const prefix = entity_id?.split('_')[0];
    switch (prefix) {
      case 'acct':
        this._router.navigate([`/merchants/${entity_id}`]);
        break;
      case 'ch':
      case 'pi':
      case 're':
        this._router.navigate([`/${account_id}/payments/${entity_id}`]);
        break;
      case 'cus':
        this._router.navigate([`/customers/${entity_id}`]);
        break;
      case 'dp':
        this._router.navigate([`/disputes/${account_id}/disputes/${entity_id}`]);
        break;
      case 'po':
        this._router.navigate([`/payouts/${account_id}/payouts/${entity_id}`]);
        break;
      case 'term':
        this._router.navigate([`/terminals/${account_id}/${entity_id}`]);
        break;
      default:
        this.idNotFound();
        break;
    }
    this.globalSearchForm.patchValue({ globalSearchInput: null });
  }

  validatePrefix(prefixValue: string): boolean {
    if (this.isMerchant) {
      if ([...this.allowedPrefixes, ...this.allowedMerchantPrefixes].includes(prefixValue)) {
        return true;
      }
    } else {
      if ([...this.allowedPrefixes, ...this.allowedPartnerPrefixes].includes(prefixValue)) {
        return true;
      }
    }
    return false;
  }

  idNotFound() {
    const message: TilledAlert = {
      message: 'The unique ID could not be found.',
      title: 'Search',
      type: 'error',
      bottom: false,
      timer: 8000,
    };
    this._alertService.showAlert(message);
  }
}
