/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BLEBankAccount } from './bLEBankAccount';
import { BaseAddress } from './baseAddress';
import { Principal } from './principal';


export interface BusinessLegalEntity { 
    /**
     * Required for US merchant accounts (`region = US`). This field is deprecated. Please use `bank_account`.
     * @deprecated
     */
    ach_bank_account?: BLEBankAccount;
    /**
     * Registered legal address of the business.
     */
    address: BaseAddress;
    /**
     * Estimated annual revenue of the business. * `LOW` = $0 - 100,000 * `MEDIUM` = $100,001 - 250,000 * `HIGH` = $250,001 - 400,000 * `VERY_HIGH` = $400,001 - 550,000 * `EXTREMELY_HIGH` = $550,000+
     */
    annual_revenue?: BusinessLegalEntity.AnnualRevenueEnum;
    /**
     * Average transaction amount, in minor units. (e.g. $100.00 = 10000 minor units)
     */
    average_transaction_amount: number;
    /**
     * Average transaction amount, in minor units for Card payments. (e.g. $100.00 = 10000 minor units)
     */
    average_transaction_amount_card?: number;
    /**
     * Average transaction amount, in minor units for Direct Debit payments. (e.g. $100.00 = 10000 minor units)
     */
    average_transaction_amount_debit?: number;
    /**
     * Average number of transactions per month
     */
    average_transactions_per_month?: number;
    /**
     * Bank Account Information
     */
    bank_account?: BLEBankAccount;
    /**
     * Category code of the business. * `ACCT` = Accounting * `ART` = Artist Supply and Craft Stores * `BEAUTY` = Barber & Beauty Shop * `CATERING` = Catering * `CHARITY` = Charity * `CLEANING` = Cleaning Services * `CONSULTANT` = Consultant * `CONTRACTOR` = Trade Contractor * `DENTIST` = Dentistry * `EDU` = Schools & Education * `FOOD` = Food/Grocery * `LANDSCAPING` = Landscaping * `LEGAL` = Legal Services * `MEDICAL_PRACT` = Medical Practitioner * `MEDICAL_SERV` = Health Services * `MEMBERSHIP` = Membership Org. * `MISC_FOOD_STORES` = Misc. Food Stores * `MISC_MERCH` = Misc General Merchandise * `MISC_SERV` = Services * `MUSIC` = Music/Entertainment * `PC` = Computer Services * `PHOTO_FILM` = Photo/FILM * `PROF_SERV` = Professional Services * `REAL_ESTATE` = Real Estate * `RECREATION` = Recreation Services * `REPAIR` = Repair Services * `RESTO` = Restaurant/Bar * `RETAIL` = Direct Marketing Retail (MOTO) * `TAXI` = Taxi/Limo * `VET` = Veterinary * `WEB_DEV` = Web Design * `WEB_HOSTING` = Web Hosting
     */
    category: BusinessLegalEntity.CategoryEnum;
    /**
     * Email of the business.
     */
    company_email?: string;
    /**
     * 2-digit Country code (e.g. \'US\', \'CA\') [See ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)
     */
    country_of_incorporation?: string;
    /**
     * Single currency used for processing and settlement for this merchant account.
     */
    currency: BusinessLegalEntity.CurrencyEnum;
    /**
     * Company\'s business description
     */
    description?: string;
    /**
     * Year the business was incorporated. A number in \'YYYY\' format.
     */
    incorporation_year?: number;
    /**
     * Registered business legal/trading name (e.g. \"ACME CA\")
     */
    legal_name: string;
    /**
     * The locale value used for the merchant account. Values depend on the region.
     */
    locale: BusinessLegalEntity.LocaleEnum;
    /**
     * Estimated monthly volume amount of the business, in minor units. (e.g. $100.00 = 10000 minor units)
     */
    monthly_volume_amount?: number;
    /**
     * The merchant name
     */
    name: string;
    /**
     * Customer support phone number of the business. Cannot contain special characters.
     */
    phone: string;
    /**
     * Array of Principals (ie Business Owners).
     */
    principals: Array<Principal>;
    /**
     * Region for the merchant account
     */
    region: BusinessLegalEntity.RegionEnum;
    /**
     * Provides information about a payment that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that’s set on the account to form the complete statement descriptor. Maximum 20 characters for the concatenated descriptor.
     */
    statement_descriptor: string;
    /**
     * Company\'s Tax Identification Number
     */
    tax_identification_number: string;
    /**
     * Type of business. * `CHARITY` = Charity * `CIC` = Community Interest Company * `CORP` = Corporation * `LTD` = Limited * `LLC` = Limited Liability Company * `LLP` = Limited Liability Partnership * `NPCORP` = Non-Profit * `PARTNERSHIP` = Partnership * `PLC` = Public Limited Company * `GOV` = Public Sector/Governmental * `SOLEPROP` = Sole Proprietorship/Sole Trader * `TRUST` = Trust
     */
    type: BusinessLegalEntity.TypeEnum;
    /**
     * Website of the business.
     */
    website?: string;
    /**
     * Estimated yearly processing volume of transactions of the business. * `LOW` = 0-50k * `MEDIUM` = 50-100k * `HIGH` = 100-250k * `VERY_HIGH` = 250k+
     */
    yearly_volume_range: BusinessLegalEntity.YearlyVolumeRangeEnum;
}
export namespace BusinessLegalEntity {
    export type AnnualRevenueEnum = 'LOW' | 'MEDIUM' | 'HIGH' | 'VERY_HIGH' | 'EXTREMELY_HIGH';
    export const AnnualRevenueEnum = {
        LOW: 'LOW' as AnnualRevenueEnum,
        MEDIUM: 'MEDIUM' as AnnualRevenueEnum,
        HIGH: 'HIGH' as AnnualRevenueEnum,
        VERY_HIGH: 'VERY_HIGH' as AnnualRevenueEnum,
        EXTREMELY_HIGH: 'EXTREMELY_HIGH' as AnnualRevenueEnum
    };
    export type CategoryEnum = 'ACCT' | 'ART' | 'BEAUTY' | 'CATERING' | 'CHARITY' | 'CLEANING' | 'CONSULTANT' | 'CONTRACTOR' | 'DENTIST' | 'EDU' | 'FOOD' | 'LANDSCAPING' | 'LEGAL' | 'MEDICAL_PRACT' | 'MEDICAL_SERV' | 'MEMBERSHIP' | 'MISC_FOOD_STORES' | 'MISC_MERCH' | 'MISC_SERV' | 'MUSIC' | 'PC' | 'PHOTO_FILM' | 'PROF_SERV' | 'REAL_ESTATE' | 'RECREATION' | 'REPAIR' | 'RESTO' | 'RETAIL' | 'TAXI' | 'UTILITY' | 'VET' | 'WEB_DEV' | 'WEB_HOSTING' | 'OTHER' | 'QUICK_PAY_FAST_FOOD' | 'PARKING' | 'CAR_WASH' | 'ELEM_SECOND_SCHOOL' | 'COLLEGE_UNIV_SCHOOL' | 'CHILD_CARE' | 'RELIGIOUS_ORG' | 'VOCA_TRADE_SCHOOL' | 'AMBULANCE' | 'DOCTOR' | 'OSTEOPATH' | 'CHIROPRACTOR' | 'EYE_DOCTOR' | 'OPTICAL' | 'PODIATRY' | 'GENERAL_CONTR' | 'HVAC_PLUMBING_CONTR' | 'ELEC_CONTR' | 'MASON_CONTR' | 'CARPENTRY_CONTR' | 'ROOF_SIDING_METAL_CONTR' | 'CONCRETE_CONTR' | 'TRUCKING_STORAGE';
    export const CategoryEnum = {
        ACCT: 'ACCT' as CategoryEnum,
        ART: 'ART' as CategoryEnum,
        BEAUTY: 'BEAUTY' as CategoryEnum,
        CATERING: 'CATERING' as CategoryEnum,
        CHARITY: 'CHARITY' as CategoryEnum,
        CLEANING: 'CLEANING' as CategoryEnum,
        CONSULTANT: 'CONSULTANT' as CategoryEnum,
        CONTRACTOR: 'CONTRACTOR' as CategoryEnum,
        DENTIST: 'DENTIST' as CategoryEnum,
        EDU: 'EDU' as CategoryEnum,
        FOOD: 'FOOD' as CategoryEnum,
        LANDSCAPING: 'LANDSCAPING' as CategoryEnum,
        LEGAL: 'LEGAL' as CategoryEnum,
        MEDICAL_PRACT: 'MEDICAL_PRACT' as CategoryEnum,
        MEDICAL_SERV: 'MEDICAL_SERV' as CategoryEnum,
        MEMBERSHIP: 'MEMBERSHIP' as CategoryEnum,
        MISC_FOOD_STORES: 'MISC_FOOD_STORES' as CategoryEnum,
        MISC_MERCH: 'MISC_MERCH' as CategoryEnum,
        MISC_SERV: 'MISC_SERV' as CategoryEnum,
        MUSIC: 'MUSIC' as CategoryEnum,
        PC: 'PC' as CategoryEnum,
        PHOTO_FILM: 'PHOTO_FILM' as CategoryEnum,
        PROF_SERV: 'PROF_SERV' as CategoryEnum,
        REAL_ESTATE: 'REAL_ESTATE' as CategoryEnum,
        RECREATION: 'RECREATION' as CategoryEnum,
        REPAIR: 'REPAIR' as CategoryEnum,
        RESTO: 'RESTO' as CategoryEnum,
        RETAIL: 'RETAIL' as CategoryEnum,
        TAXI: 'TAXI' as CategoryEnum,
        UTILITY: 'UTILITY' as CategoryEnum,
        VET: 'VET' as CategoryEnum,
        WEB_DEV: 'WEB_DEV' as CategoryEnum,
        WEB_HOSTING: 'WEB_HOSTING' as CategoryEnum,
        OTHER: 'OTHER' as CategoryEnum,
        QUICK_PAY_FAST_FOOD: 'QUICK_PAY_FAST_FOOD' as CategoryEnum,
        PARKING: 'PARKING' as CategoryEnum,
        CAR_WASH: 'CAR_WASH' as CategoryEnum,
        ELEM_SECOND_SCHOOL: 'ELEM_SECOND_SCHOOL' as CategoryEnum,
        COLLEGE_UNIV_SCHOOL: 'COLLEGE_UNIV_SCHOOL' as CategoryEnum,
        CHILD_CARE: 'CHILD_CARE' as CategoryEnum,
        RELIGIOUS_ORG: 'RELIGIOUS_ORG' as CategoryEnum,
        VOCA_TRADE_SCHOOL: 'VOCA_TRADE_SCHOOL' as CategoryEnum,
        AMBULANCE: 'AMBULANCE' as CategoryEnum,
        DOCTOR: 'DOCTOR' as CategoryEnum,
        OSTEOPATH: 'OSTEOPATH' as CategoryEnum,
        CHIROPRACTOR: 'CHIROPRACTOR' as CategoryEnum,
        EYE_DOCTOR: 'EYE_DOCTOR' as CategoryEnum,
        OPTICAL: 'OPTICAL' as CategoryEnum,
        PODIATRY: 'PODIATRY' as CategoryEnum,
        GENERAL_CONTR: 'GENERAL_CONTR' as CategoryEnum,
        HVAC_PLUMBING_CONTR: 'HVAC_PLUMBING_CONTR' as CategoryEnum,
        ELEC_CONTR: 'ELEC_CONTR' as CategoryEnum,
        MASON_CONTR: 'MASON_CONTR' as CategoryEnum,
        CARPENTRY_CONTR: 'CARPENTRY_CONTR' as CategoryEnum,
        ROOF_SIDING_METAL_CONTR: 'ROOF_SIDING_METAL_CONTR' as CategoryEnum,
        CONCRETE_CONTR: 'CONCRETE_CONTR' as CategoryEnum,
        TRUCKING_STORAGE: 'TRUCKING_STORAGE' as CategoryEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type LocaleEnum = 'en_US' | 'en_CA' | 'fr_CA' | 'en_GB';
    export const LocaleEnum = {
        EN_US: 'en_US' as LocaleEnum,
        EN_CA: 'en_CA' as LocaleEnum,
        FR_CA: 'fr_CA' as LocaleEnum,
        EN_GB: 'en_GB' as LocaleEnum
    };
    export type RegionEnum = 'US' | 'CA';
    export const RegionEnum = {
        US: 'US' as RegionEnum,
        CA: 'CA' as RegionEnum
    };
    export type TypeEnum = 'CHARITY' | 'CIC' | 'CORP' | 'LTD' | 'LLC' | 'LLP' | 'NPCORP' | 'PARTNERSHIP' | 'PLC' | 'GOV' | 'SOLEPROP' | 'TRUST';
    export const TypeEnum = {
        CHARITY: 'CHARITY' as TypeEnum,
        CIC: 'CIC' as TypeEnum,
        CORP: 'CORP' as TypeEnum,
        LTD: 'LTD' as TypeEnum,
        LLC: 'LLC' as TypeEnum,
        LLP: 'LLP' as TypeEnum,
        NPCORP: 'NPCORP' as TypeEnum,
        PARTNERSHIP: 'PARTNERSHIP' as TypeEnum,
        PLC: 'PLC' as TypeEnum,
        GOV: 'GOV' as TypeEnum,
        SOLEPROP: 'SOLEPROP' as TypeEnum,
        TRUST: 'TRUST' as TypeEnum
    };
    export type YearlyVolumeRangeEnum = 'LOW' | 'MEDIUM' | 'HIGH' | 'VERY_HIGH';
    export const YearlyVolumeRangeEnum = {
        LOW: 'LOW' as YearlyVolumeRangeEnum,
        MEDIUM: 'MEDIUM' as YearlyVolumeRangeEnum,
        HIGH: 'HIGH' as YearlyVolumeRangeEnum,
        VERY_HIGH: 'VERY_HIGH' as YearlyVolumeRangeEnum
    };
}


