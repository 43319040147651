/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Markup { 
    /**
     * The card type this markup applies to.
     */
    card_type: Markup.CardTypeEnum;
    /**
     * The amount of the markup applied to this card type.
     */
    rate: number;
}
export namespace Markup {
    export type CardTypeEnum = 'amex' | 'diners' | 'discover' | 'jcb' | 'maestro' | 'mastercard' | 'solo' | 'visa' | 'visa_debit' | 'visa_electron' | 'unknown';
    export const CardTypeEnum = {
        AMEX: 'amex' as CardTypeEnum,
        DINERS: 'diners' as CardTypeEnum,
        DISCOVER: 'discover' as CardTypeEnum,
        JCB: 'jcb' as CardTypeEnum,
        MAESTRO: 'maestro' as CardTypeEnum,
        MASTERCARD: 'mastercard' as CardTypeEnum,
        SOLO: 'solo' as CardTypeEnum,
        VISA: 'visa' as CardTypeEnum,
        VISA_DEBIT: 'visa_debit' as CardTypeEnum,
        VISA_ELECTRON: 'visa_electron' as CardTypeEnum,
        UNKNOWN: 'unknown' as CardTypeEnum
    };
}


