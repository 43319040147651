/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BankAccountUpdateParams { 
    /**
     * The name of the person or business that owns the bank account.
     */
    account_holder_name?: string;
    /**
     * The account number for the bank account, in string form.
     */
    account_number?: string;
    /**
     * Name of the financial institution.
     */
    bank_name?: string;
    /**
     * The currency the bank account is in. Three-letter ISO currency code, in lowercase.
     */
    currency?: BankAccountUpdateParams.CurrencyEnum;
    id?: string;
    /**
     * Phone number for the financial institution
     */
    phone?: string;
    /**
     * The US bank account routing number.
     */
    routing_number?: string;
    /**
     * The bank account type
     */
    type?: BankAccountUpdateParams.TypeEnum;
}
export namespace BankAccountUpdateParams {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type TypeEnum = 'checking' | 'savings';
    export const TypeEnum = {
        CHECKING: 'checking' as TypeEnum,
        SAVINGS: 'savings' as TypeEnum
    };
}


